import React from "react";
import ContactForm from "../Contact Services/ContacServices";
import FeaturedServices from "../Featured Services/FeaturedServices";
import ServicePage from "../Hero_section/Hero";
import ProductSlideShow from "../ourService/ProductSlideshow";
import PopularServices from "../popular services/PopularServices";
import Footer from './../Footer/Footer';
import TopBar from './../topbar/TopBar';

const HomeRoute = () => {
  return (
    <div>
      <TopBar/>
      <ServicePage />
      <FeaturedServices />
      <ProductSlideShow />
      <PopularServices />
      <ContactForm />
      <Footer/>
    </div>
  );
};

export default HomeRoute;
